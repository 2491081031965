@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'PoetsenOne';
  src: url('./Fonts/PoetsenOne-Regular.ttf');
}

@font-face {
  font-family: 'Jaro';
  src: url('./Fonts/Jaro-Regular-VariableFont_opsz.ttf');
}
@font-face {
  font-family: 'Montserrat';
  src: url('./Fonts/Montserrat-Regular.ttf');
}

body{
  color: white;
  background: rgb(0,0,0);
  background: linear-gradient(180deg, rgba(0,0,0,1) 70%, rgba(13,39,89,1) 110%);  
  background-repeat: no-repeat;
  background-attachment: fixed;
}
svg{
  display:inline;
}
button{
  font-family: 'Jaro';
  font-size: 1.5rem;
  background-color: #0D275A;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.3s;
}
button:hover{
  background-color: #184296;
  color: #dcdcdc;
}
button:active{
  transform: scale(0.95);
}
button:focus{
  outline: none;
}

a{
  color: #6ed1ff;
  text-decoration: none;
}
a:hover{
  color: #b5e6fc;
}

h1{
  font-size: 3rem;
  font-family: 'Montserrat';
  margin: 20px 0;
}
h3{
  font-size: 2rem;
  font-family: 'Montserrat';
  margin: 10px 0;
}

h6{
  font-size: 1.5rem;
  font-family: 'Montserrat';
  margin: 10px 0;
}

p{
  margin: 10px 0;

}

.page-container{
  padding: 20px;
}


.logo-parent{
  position: relative;
  width: 40vw;
  height: auto;
}



.logo{
  width:100%;
	height:auto;	

}

.logo-ghost{
  position: absolute;
  width: 20%;
  top: 70%;
  left: 8.3vw;
}

@media screen and (max-width: 768px) {
  .logo-parent{
    width: 80vw;
  }
  .logo-ghost{
    width: 17%;
    top: 70%;
    left: 18vw;
  }
  
}

@media (prefers-reduced-motion: no-preference) {
  .mascot-logo {
    animation: mascot-float-up-and-down infinite 5s linear;
  }
}

.mascot-logo {
  /* width: 100px; */
}

.title {
  font-family: 'PoetsenOne';
  text-shadow: #0D275A 2px 2px 2px;
}

@keyframes mascot-float-up-and-down {
  0%, 100% {
    transform: translateY(-40%);
  }
  50% {
    transform: translateY(-60%);
  }
}


.wishlist-button{
  /* background-color: transparent !important; */
  position: relative;
}

.wishlist-button:hover{
  background-color: #000000;
  /* color: #dcdcdc; */
}

@property --angle{
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

.wishlist-button::after, .wishlist-button::before{
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: conic-gradient(from var(--angle), #ff4545, #00ff99, #006aff, #ff0095, #ff4545);
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  z-index: -1;
  padding: 30px 115px;
  border-radius: 5px;
  animation: 3s spin linear infinite;
}
.wishlist-button::before{
  filter: blur(1.0rem);
  opacity: 0.8;
}
@keyframes spin{
  from{
    --angle: 0deg;
  }
  to{
    --angle: 360deg;
  }
}